import htmx from 'htmx.org'

// import 'idiomorph'
// https://github.com/bigskysoftware/htmx/issues/887
// Seems loading htmx exts.
// using parcel doesn't work.
// so they have to be included as script tags in the header for now.
window.htmx = htmx

// ADD A POPSTATE BACK FORWARD EVENT.
// so we can hook into the modals opening and closing.

// Keep track of the current position
let currentIndex = (history.state && history.state.index) || 0;

// Set initial index, before replacing setters
if (!history.state || !('index' in history.state)) {
    history.replaceState(
        {index: currentIndex, state: history.state},
        document.title
    );
}

// Native functions
const getState = Object.getOwnPropertyDescriptor(History.prototype, 'state').get;
const {pushState, replaceState} = history;

// Detect forward and back changes
function onPopstate() {
    const state = getState.call(history);

    // State is unset when `location.hash` is set. Update with incremented index
    if (!state) {
        replaceState.call(history, {index: currentIndex + 1}, document.title);
    }
    const index = state ? state.index : currentIndex + 1;

    const direction = index > currentIndex ? 'forward' : 'back';
    window.dispatchEvent(new Event(direction));

    currentIndex = index;
}

// Create functions which modify index
function modifyStateFunction(func, n) {
    return (state, ...args) => {
        func.call(history, {index: currentIndex + n, state}, ...args);
        // Only update currentIndex if call succeeded
        currentIndex += n;
    };
}

// Override getter to only return the real state
function modifyStateGetter(cls) {
    const {get} = Object.getOwnPropertyDescriptor(cls.prototype, 'state');

    Object.defineProperty(cls.prototype, 'state', {
        configurable: true,
        enumerable: true,
        get() {
            return get.call(this).state;
        },
        set: undefined
    });
}

modifyStateGetter(History);
modifyStateGetter(PopStateEvent);
history.pushState = modifyStateFunction(pushState, 1);
history.replaceState = modifyStateFunction(replaceState, 0);
window.addEventListener('popstate', onPopstate);

// If there is a dialog open, and the user clicks the back button,
// or we programmatically go back via the history api.
// Don't trigger the actual popstate back, instead, close the current dialog.
// Each dialog when opened via HTMX pushes a new URL into the stack so the location
// should always still represent the correct application state.
// This process just makes the UI smoother because it's not loading an
// entirely new DOM structure each time.
// Rather just showing and hiding the most recent modal.
window.direction = ""

window.addEventListener('popstate', (e) => {
    const dialogs = document.querySelectorAll("#dialogs .dialog[open]")
    if (dialogs.length > 0 && window.direction === "back") {
        e.stopImmediatePropagation()
        dialogs[dialogs.length - 1].close()

        if (!window.isMobile()) {
            let x = 0;
            els = [...dialogs].reverse()
            els.shift()
            els.forEach(el => {
                el.style.transform = `translateX(${x}px)`
                x = x - 20;
            });
        }
    }

    const dialogs2 = document.querySelectorAll('#dialogs .dialog:not([open]):not(:empty)')
    if (dialogs2.length > 0 && window.direction === "forward") {
        e.stopImmediatePropagation()
        dialogs2[0].showModal()

        if (!window.isMobile()) {
            let x = 0;
            const openDialogs = document.querySelectorAll("#dialogs .dialog[open]")
            els.shift()
            els = [...openDialogs].reverse()
            els.forEach(el => {
                el.style.transform = `translateX(${x}px)`
                x = x - 20;
            });
        }
    }
});

// The script to add the event listeners for going back and forward rather than just
// popstate fires before the popstate event listener above.
// So we can set the global here and reference it in popstate listener.
// We can use these events for instead of popstate because they can't stopPropagation
// of popstate which is what we need to cancel the htmx page transitions when a modal is open.
window.addEventListener('back', (e) => {
    window.direction = "back"
});

window.addEventListener('forward', (e) => {
    window.direction = "forward"
});

window.afterSwap = (dialog) => {
    console.log("ok")
    dialog.showModal();
    let x = 0;
    let s = 1;
    let els = document.querySelectorAll('#dialogs .dialog[open]');
    if (!window.isMobile()) {
        els = [...els].reverse()
        els.forEach(el => {
            el.style.transform = `translateX(${x}px)`;
            x = x - 20;
        });
    }
    window.stopMobilePushURLOnDialogs()
    window.createDialog()
}


window.createDialog = function () {

    // So we don't end up with a huge number of unused dialogs.
    // Once we've clicked a new one, the old ones can't be accessed via
    // the history api anymore so we can remove all the closed ones.
    const unusedDialogs = document.querySelectorAll('#dialogs .dialog:not([open])')
    if (unusedDialogs.length > 0) {
        for (let i = 0; i < unusedDialogs.length; i++) {
            unusedDialogs[i].remove()
        }
    }

    const el = document.createElement("dialog")
    el.classList.add("dialog")
    el.setAttribute("hx-on-click", "if (event.target === event.currentTarget) {window.history.back();}")
    el.setAttribute("hx-on::after-swap", 'window.afterSwap(this)')
    el.setAttribute("hx-preserve", 'true')
    document.querySelector('#dialogs').appendChild(el)
    htmx.process(el)
}

// If an action is performed on the server side that invalidates the malls current state.
// clear all the htmx history so a fresh copy of all content will be fetched on subsequent pages.
window.addEventListener('refreshMall', (e) => {
    localStorage.removeItem('htmx-history-cache')
})


window.getContrastYIQ = function (hexcolor) {
    var r = parseInt(hexcolor.substring(1, 3), 16);
    var g = parseInt(hexcolor.substring(3, 5), 16);
    var b = parseInt(hexcolor.substring(5, 7), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

window.addEventListener("load", () => {
    const width = window.innerWidth;
    if (width < 640) {
        const sidebar = document.querySelector('.page-sidebar')
        if (sidebar) {
            sidebar.classList.add('-fixed');
        }
    }
})

window.isMobile = () => {
    const width = window.innerWidth;
    if (width < 640) {
        return true
    }
}

window.stopMobilePushURLOnDialogs = function () {
    els = document.querySelectorAll('a[hx-target="#dialogs .dialog:last-child"]')
    els.forEach(el => {
        if (window.isMobile()) {
            el.setAttribute("hx-push-url", "false")
        }
    })
}